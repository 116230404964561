import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loader from "../src/component/lazyLoading"

// Lazy load components with delay
const delayedImport = (importFn, delay = 1000) => {
  return Promise.all([
    importFn(),
    new Promise(resolve => setTimeout(resolve, delay))
  ]).then(([moduleExports]) => moduleExports);
};

const Home = lazy(() => delayedImport(() => import("./pages/HomePage")));
const AboutPage = lazy(() => delayedImport(() => import("./pages/AboutPage")));
const AccommodationsPage = lazy(() => delayedImport(() => import("./pages/AccommodationsPage")));
const SafariPage = lazy(() => delayedImport(() => import("./pages/SafariPage")));
const ItineraryDetails = lazy(() => delayedImport(() => import("../src/component/Safari/ItineraryDetails")));
const ActivitiesPage = lazy(() => delayedImport(() => import("./pages/ActivitiesPage")));
const ContactPage = lazy(() => delayedImport(() => import("./pages/ContactPage")));
const CustomItineraryForm = lazy(() => delayedImport(() => import("../src/component/Home/CustomItineraryForm")));
const DestinationPage = lazy(() => delayedImport(() => import("./pages/DestinationPage")));
const DestinationDetails = lazy(() => delayedImport(() => import("./component/Destination/DestinationDetails")));


// Create a wrapper component for lazy-loaded routes
const LazyComponent = ({ component: Component }) => (
  <Suspense fallback={<Loader />}>
    <Component />
  </Suspense>
);

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LazyComponent component={Home} />} />
        <Route path="/about" element={<LazyComponent component={AboutPage} />} />
        <Route path="/destination" element={<LazyComponent component={DestinationPage} />} />
        <Route path="/destination/:id" element={<LazyComponent component={DestinationDetails} />} />
        <Route path="/accommodations" element={<LazyComponent component={AccommodationsPage} />} />
        <Route path="/safari" element={<LazyComponent component={SafariPage} />} />
        <Route path="/safari/:id" element={<LazyComponent component={ItineraryDetails} />} />
        <Route path="/activities" element={<LazyComponent component={ActivitiesPage} />} />
        <Route path="/contact" element={<LazyComponent component={ContactPage} />} />
        <Route path="/enquiry-form" element={<LazyComponent component={CustomItineraryForm} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
